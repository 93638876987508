import React, { useState, useEffect } from "react";
import NavBar from "../components/navbar";
import CounterSection from "../components/CounterSection";
import Accreditations from "../components/Accreditations"; // Import the Accreditations component
import ContactUs from "../components/ContactUs"; // Import the new ContactUs component
import heroImageDesktop from "../assets//Mahonys-HeroSection.jpg";
import heroImageMobile from "../assets/Mahonys-Dark-Kenworth.jpg"; // Import your mobile hero image
// Import service images
import truckOnRoad from "../assets/truck-on-road.jpg";
import warehouseTrucks from "../assets/warehouse-trucks.jpg";
import kenworth from "../assets/kenworth-truck.jpg";
import Mahonys_green from "../assets/Mahonys-Green-Truck.JPG";

import Footer from "../components/footer";

import { Link, useNavigate } from "react-router-dom";
import { ServiceCards } from "./Services";

function HomePage() {
  const [heroImage, setHeroImage] = useState(heroImageDesktop);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 700) {
        setHeroImage(heroImageMobile);
      } else {
        setHeroImage(heroImageDesktop);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <div className=" relative h-[99vh] mx-auto max-w-[97rem] p-5 ">
        <div className="rounded-lg overflow-hidden h-full relative">
          <img
            src={heroImage}
            alt="Mahonys Transport Services"
            className="w-full h-full object-fit absolute inset-0 max-[700px]:object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-30">
            {/* NavBar positioned absolutely within the hero section */}
            <div className="absolute top-0 left-0 right-0 z-10">
              <NavBar />
            </div>

            <div className="h-full flex flex-col justify-between px-4 sm:px-10 py-10 ml-2 sm:ml-5">
              <h1 className="text-white text-4xl sm:text-xl lg:text-7xl font-bold mb-4 mt-32 sm:mt-[140px] leading-tight">
                MAHONYS
                <br />
                TRANSPORT
                <br />
                SERVICES
              </h1>
              <p className="text-white text-sm sm:text-base lg:text-lg mb-6 sm:mb-10">
                Seamless logistics solutions for
                <br />
                efficient cargo transport.
              </p>
            </div>
          </div>
          <div className="py-3 px-4 absolute top-24 right-3 sm:top-24 sm:right-20 flex flex-row bg-black bg-opacity-50 rounded-lg w-auto min-w-[200px] sm:min-w-[400px] space-x-2 sm:space-x-4">
            <a
              href="https://app.acuityscheduling.com/schedule.php?owner=21408127&calendarID=7099850&ref=booking_button"
              target="_blank"
              className="acuity-embed-button text-xs sm:text-base px-2 sm:px-3 py-1 sm:py-2 bg-green-500 text-white rounded-md"
            >
              Book Cotton Time Slot
            </a>

            <link
              rel="stylesheet"
              href="https://embed.acuityscheduling.com/embed/button/21408127.css"
              id="acuity-button-styles"
            />
            <script
              src="https://embed.acuityscheduling.com/embed/button/21408127.js"
              async
            ></script>

            <a
              href="https://app.acuityscheduling.com/schedule.php?owner=21408127&ref=booking_button"
              target="_blank"
              className="acuity-embed-button text-xs sm:text-base px-2 sm:px-3 py-1 sm:py-2 bg-green-500 text-white rounded-md"
            >
              Book Laverton Truck Time Slot
            </a>

            <link
              rel="stylesheet"
              href="https://embed.acuityscheduling.com/embed/button/21408127.css"
              id="acuity-button-styles"
            />
            <script
              src="https://embed.acuityscheduling.com/embed/button/21408127.js"
              async
            ></script>
          </div>
          <div className="hidden sm:absolute sm:top-40 sm:right-5 sm:bg-opacity-50 sm:text-white sm:p-4 sm:max-w-xs sm:rounded-lg sm:block">
            <p className="text-sm">
              Mahonys Transport Services draws on years of experience in the
              agricultural industry to provide top-quality services.
            </p>
          </div>
        </div>
      </div>

      {/* Our Services Section */}
      <div className="container mx-auto py-6 w-[90%]">
        <h2 className="text-3xl font-bold text-center mb-8 text-green-800">
          OUR SERVICES
        </h2>
        <ServiceCards />
      </div>

      {/* About Mahonys Section */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto w-[90%]">
          <h2 className="text-2xl font-bold mb-4 text-green-800">Our Story</h2>
          <p className="text-sm mb-4">
            For nearly three decades, Mahonys Transport Services has been at the
            forefront of the transportation industry. Born in 1997 as a modest
            local operation, we've blossomed into a comprehensive logistics
            powerhouse, serving clients across Australia. Our journey is a
            testament to the power of family values in business, as we've grown
            from a single owner-driver to a dynamic team of logistics experts.
          </p>
          <p className="text-sm mb-4">
            Mahonys values an unwavering commitment to reliability, efficiency,
            and customer satisfaction. We've ridden the waves of industry
            changes, continually investing in cutting-edge technology and
            expanding our services to meet our clients' evolving needs.
          </p>

          <Link
            to="/about"
            className="text-green-800 font-bold hover:underline"
          >
            More About Us &gt;
          </Link>
        </div>
      </div>

      {/* Counter Section */}
      <CounterSection />

      {/* Accreditations Section (replacing News Section) */}
      <Accreditations />

      {/* Logistics & Management Solutions Section */}
      <div className="container mx-auto py-12 w-[90%]">
        <div className="mb-8  p-4 inline-block">
          <h2 className="text-3xl font-bold text-green-800 mb-2">
            LOGISTICS & MANAGEMENT SOLUTIONS
          </h2>
          <p className="text-gray-600">
            So that you can easily and rapidly manage inventory and orders.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="rounded-lg overflow-hidden h-64">
            {" "}
            {/* Set a fixed height */}
            <img
              src={truckOnRoad}
              alt="Truck on road"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="rounded-lg overflow-hidden h-64">
            {" "}
            {/* Set a fixed height */}
            <img
              src={Mahonys_green}
              alt="Trucks in warehouse"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="rounded-lg overflow-hidden h-64 relative">
            {" "}
            {/* Set a fixed height and relative positioning */}
            <img
              src={kenworth}
              alt="Kenworth truck"
              className="w-full h-[120%] object-cover absolute bottom-0"
            />
          </div>
        </div>

        <div className=" py-20">
          {/* Contact Form */}
          <ContactUs />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HomePage;
