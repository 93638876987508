import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import BreadCrumb from "../components/BreadCrumb";
import { Link } from "react-router-dom";
import sustainabilityImage from "../assets/sustainability-mahonys.jpg";
import Hero from "../components/Hero";

const Sustainability = () => {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Sustainability", "/sustainability"],
  };

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        title={"Sustainability"}
        subText={"Seamless Container Solutions for Global Trade Efficiency"}
        crumb={crumb}
        img={sustainabilityImage}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        <h2 className="text-3xl font-bold mb-6 text-green-800">
          Sustainability at Mahonys
        </h2>
        <p className="mb-6">
        Mahonys Transport Services is committed to promoting sustainability across the agriculture industry. Our company has implemented a range of initiatives that aim at reducing our carbon footprint. 
        </p>
        <p className="mb-6">
        Mahonys are participating in the Victorian Energy Efficiency Scheme to reduce electricity consumption and cut carbon emissions.
        </p>
        <p className="mb-6">
        We advocate for sustainable farming practices among its grain producers and encourages the adoption of environmentally responsible methods in crop production. 
        </p>
        <p className="mb-6">
          For more information on how Mahonys Transport Services is promoting
          sustainability in the grain industry, please contact us.
        </p>
        <Link
          to="/contactUs"
          className="text-green-800 font-bold hover:underline"
        >
          Contact Us &gt;
        </Link>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Sustainability;
