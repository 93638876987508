import React from "react";
import heroImage from "../assets/Mahonys-HeroSection.jpg"; // Import your hero image
import NavBar from "../components/navbar";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";
import CoR from "../assets/CoR.png";
import NHVR from "../assets/NHVR-new.png";

const Compliance = () => {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Compliance", "/compliance"],
  };

  const acc = [
    {
      image: CoR,
      title: "Chain of Responsibility",
      description: "CoR Member",
    },
    {
      image: NHVR,
      title: "National Heavy Vehicle Registry",
      description: "NHVR Member Number: NHVAS31732",
    },
  ];

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={heroImage}
        title={"Compliance"}
        subText={"Fleet and Operations Compliance"}
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        <h2 className="text-3xl font-bold mb-6 text-green-800">
          Occupational Health & Safety
        </h2>
        <p>
          Mahonys Transport Services is&nbsp;committed to complying with all
          relevant Legislation, Acts, Regulations, Codes of Practice and
          recognised Industry Standards to uphold high levels of safety for all
          employees, clients, visitors, contractors and the general public.
        </p>
        <br></br>
        <p>
          All Mahonys employees, visitors and contractors are also required to
          sign and abide by all Mahonys Policies and Procedures including our
          Occupation Health and Safety (OH&amp;S) Policy and follow all relevant
          Safe Operating Procedures (SOPs).
        </p>
        <br></br>
        <p>
          Mahonys approach to Occupational Health &amp; Safety (OH&amp;S) is one
          of continuous improvement through the enhancement of skills,
          knowledge, practice and commitment of our employees at all levels of
          the organisation.
        </p>
        <br></br>
        <p>Mahonys Transport’s safety and compliance objectives are to;</p>
        <br></br>
        <ul className="list-disc pl-5">
          <li>
            Comply with relevant Legislation, Acts, Regulations, Codes of
            Practice and recognised Industry Standards.
          </li>
          <li>
            Provide employees with appropriate skills, resources, and support to
            enable them to diligently carry out their work with due
            considerations of others in the workplace.
          </li>
          <li>
            Continue to develop a culture that encourages all employees to
            participate in meeting the objectives of this policy.
          </li>
          <li>
            Monitor and record relevant evidence of OH&amp;S performance
            objectives and improvements achieved.
          </li>
          <li>
            Control those aspects of its operations that have an affect on
            OH&amp;S.
          </li>
          <li>Assign relevant responsibility for OH&amp;S to employees.</li>
        </ul>
        <br></br>
        <p>
          In fulfilling these objectives, management is committed to
          consultation with employees and other stakeholders to ensure that the
          policy operates effectively and that health &amp; safety issues are
          regularly reviewed.
        </p>
        <br></br>
        <p>
          The commitment to Safety and Compliance is endorsed by the Board of
          Directors, driven by Senior Management and extends to all levels of
          the organisation.
        </p>
        <br></br>

        <h2 className="text-3xl font-bold mb-6 mt-6 text-green-800">
          Fleet Compliance
        </h2>
        <p className="mb-6">
          Mahonys Transport Services exemplifies regulatory compliance, fully
          accredited by the National Heavy Vehicle Regulator across Mass,
          Maintenance, Fatigue, and Chain of Responsibility mandates. Their
          robust Chain of Responsibility Heavy Vehicle Risk Assessment and
          supporting policies meticulously document risk mitigation strategies,
          reflecting an unwavering commitment to proactive risk management and
          operational excellence.
        </p>
        <p className="mb-6">
          Mahonys Transport Services upholds stringent operational standards to
          ensure service excellence. All operators hold current MC licenses,
          subject to internal audits every 12 months, with some having obtained
          Basic Fatigue Management certification.
        </p>
        <p className="mb-6">
          Demonstrating a commitment to regulatory compliance, Mahonys Transport
          is accredited in all three NHVR legislated areas of Mass, Fatigue, and
          Maintenance, with the added coverage of Chain of Responsibility.
          Rigorous auditing practices include NHVR audits every two years,
          complemented by weekly internal fatigue, mass, and speed audits, as
          well as monthly Chain of Responsibility training for all operators and
          staff.
        </p>
        <p className="mb-6">
          Mahonys Transport's fleet is fully compliant, with all vehicles
          registered under the appropriate heavy vehicle scheme (HML or PBS),
          complete with the required mass, dimensions approvals, and permits for
          Victorian road operations.
        </p>

        <Link
          to="/contactUs"
          className="text-green-800 font-bold hover:underline"
        >
          Contact Us &gt;
        </Link>
        <div className="container mx-auto my-10 w-[60%]">
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-2`}>
            {acc.map((service, index) => (
              <div className="flex flex-col items-center group">
                <div className="relative overflow-hidden rounded-lg mb-4">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="w-50 h-48 object-fill"
                  />
                </div>
                <p className="font-bold text-center">{service.title}</p>
                <p className="text-xs mb-2 flex-grow">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Compliance;
