import React from "react";
import Slider from "react-slick";
import HaccpLogo from "../assets/haccp_aust_cert_black.jpg";
import GTALogo from "../assets/Grain_trade_australia.jpeg";
import NHVR from "../assets/NHVR-new.png";
import AQIS from "../assets/DAFF_logo.jpeg";
import GACC from "../assets/GACC-Mahonys.png";
import CoR from "../assets/CoR.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Accreditations = () => {
  const accreditations = [
    {
      icon: HaccpLogo,
      title: "HACCP CERTIFIED",
      description: "Member Number: FSP-1639-MAH-02",
      source: "December 2022",
    },
    {
      icon: AQIS,
      title: "DAFF Export Registration",
      description: "Export Registered Site: 6851",
      source: "January 2020",
    },
    {
      icon: GTALogo,
      title: "Grain Trade Australia",
      description: "GTA Member",
      source: "May 2020",
    },
    {
      icon: NHVR,
      title: "National Heavy Vehicle Registry",
      description: "NHVR Member Number: NHVAS31732",
      source: "June 2016",
    },
    {
      icon: AQIS,
      title: "DAFF Approved Arrangement",
      description: "Member: V3196",
      source: "January 2020",
    },
    {
      icon: GACC,
      title: "GACC",
      description: "Member Number: CAUS09012112010022",
      source: "2023",
    },
    {
      icon: CoR,
      title: "Chain of Responsibility",
      description: "CoR Member",
      source: "2023",
    },
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-[#014432] py-12 px-4">
      <h2 className="text-3xl font-bold text-center text-gray-100 mb-8">
        ACCREDITATIONS AND CERTIFICATIONS
      </h2>
      <div className="max-w-8xl  mx-auto">
        <Slider {...settings} className="!flex h-200 ">
          {accreditations.map((accreditation, index) => (
            <div key={index} className="px-2 h-full py-5">
              <div className="bg-gray-100 text-black p-6 rounded-lg shadow-md flex flex-col items-center text-center h-full transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                <div className="w-16 h-16 bg-white flex items-center justify-center mb-4">
                  <img
                    src={accreditation.icon}
                    alt={accreditation.title}
                    className="w-16 h-16 object-contain"
                  />
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  {accreditation.title}
                </h3>
                <p className="text-sm mb-2 flex-grow">
                  {accreditation.description}
                </p>
                {/*<p className="text-xs italic mt-auto">{accreditation.source}</p>}*/}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Accreditations;
