import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import heroImage from "../assets/Mahonys-HeroSection.jpg";
import importExportImage from "../assets/Mahonys-Dark-Kenworth.jpg";
import transportLogisticsImage from "../assets/Mahonys-Scania-Kenworth.jpg";
import bulkStorageImage from "../assets/Mahonys-Kenworth-Shed.jpg";
import { Link } from "react-router-dom";

import Hero from "../components/Hero";

export const ServiceCards = ({ hiddenTitle }) => {
  const services = [
    {
      image: importExportImage,
      title: "IMPORT & EXPORT CONTAINERS PACKING",
      msg: "Import Bulk Commodity Containers Mahonys Transport Service is able to provide a high quality service Importing all bulk and containerised bi-products, stock feeds and fertilisers.",
      link: "import_export",
      crumb: {
        Home: ["Home", "/"],
        Services: ["Services", "/services"],
        ImportExport: [
          "Import & Export Containers Packing",
          "/services/import_export",
        ],
      },
    },
    {
      image: transportLogisticsImage,
      title: "TRANSPORT & LOGISTICS",
      msg: "Mahonys Transport’s high-quality transport and logistics division has the ability to deliver a range of products on time, within budget and with the right image.",
      link: "logistics",
      crumb: {
        Home: ["Home", "/"],
        Services: ["Services", "/services"],
        Logistics: ["Transport & Logistics", "/services/logistics"],
      },
    },
    {
      image: bulkStorageImage,
      title: "BULK IMPORT & STORAGE",
      msg: "Mahonys Transport Services provides safe bulk storage of a range of different commodities - all in one convenient location close to the Melbourne Wharf.",
      link: "fumo",
      crumb: {
        Home: ["Home", "/"],
        Services: ["Services", "/services"],
        Storage: ["Bulk Import & Storage", "/services/fumo"],
      },
    },
  ];

  // Filter out the service with the title that matches hiddenTitle
  const filteredServices = services.filter(
    (service) => service.title !== hiddenTitle
  );

  return (
    <div
      className={`container mx-auto my-10 ${
        filteredServices.length > 2 ? "w-[90%]" : "w-[60%]"
      }`}
    >
      <div
        className={`grid grid-cols-1 md:grid-cols-${filteredServices.length} gap-6`}
      >
        {filteredServices.map((service, index) => (
          <Link to={`/services/${service.link}`} key={index}>
            <div className="flex flex-col items-center group cursor-pointer">
              <div className="relative overflow-hidden rounded-lg mb-4">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
                  <p className="text-white text-center px-4">{service.msg}</p>
                </div>
              </div>
              <p className="font-bold text-center">{service.title}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

function Services() {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Services", "/services"],
  };

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={heroImage}
        title={"Services"}
        subText={"What we do and what can you expect from us"}
        crumb={crumb}
      />

      {/* Main Content */}
      <ServiceCards />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Services;
