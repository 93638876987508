import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Services from "./pages/Services";
import ImportExport from "./pages/ImportExportPage";
import Compliance from "./pages/Compliance";
import Logistics from "./pages/LogisticsPage";
import ContactUs from "./pages/ContactUs";
import Sustainability from "./pages/Sustainability";
import FumigationStorage from "./pages/FumigationPage";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/import_export" element={<ImportExport />} />
        <Route path="/services/logistics" element={<Logistics />} />
        <Route path="/services/fumo" element={<FumigationStorage />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/compliance" element={<Compliance />} />
      </Routes>
    </Router>
  );
}

export default App;
