import React from "react";
import logo from "../assets/logo.JPG"; // Make sure the path is correct
import LinkedInLogo from "../assets/Mahonys-Linkedin.png";
import FacebookLogo from "../assets/Mahonys-Facebook.png";
function Footer() {
  return (
    <footer className="bg-[#014432] text-white py-12">
      <div className="container mx-auto w-[90%]">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <img
              src={logo}
              alt="Mahonys Transport Services Logo"
              className="w-40 mb-4"
            />
            <p className="text-sm">
              Seamless Logistics solution for efficient cargo transportation
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="hover:underline">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="hover:underline">
                  About Us
                </a>
              </li>
              <li>
                <a href="/services" className="hover:underline">
                  Services
                </a>
              </li>
              <li>
                <a href="/contactUs" className="hover:underline">
                  Contact
                </a>
              </li>
              <li>
                <a href="/sustainability" className="hover:underline">
                  Sustainability
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Services</h3>
            <ul className="space-y-2">
              <li>
                <a href="/services/import_export" className="hover:underline">
                  Import & Export Containers
                </a>
              </li>
              <li>
                <a href="/services/logistics" className="hover:underline">
                  Transport & Logistics
                </a>
              </li>
              <li>
                <a href="/services/fumo" className="hover:underline">
                  Bulk Storage & Fumigation
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Contact Us</h3>
            <p className="mb-2">
              115-117 William Angliss Drive, Laverton North, Melbourne, VIC 3026
            </p>
            <p className="mb-2">Phone Logistics: 03 9371 8750</p>
            <p className="mb-2">Phone Import/Export: 03 9371 8700</p>
            <p className="mb-2">Email: info@mahonystransport.com.au</p>
            <div className="flex space-x-4 mt-4">
              <a href="https://www.facebook.com/mahonystransport" className="hover:text-gray-300">
                <img className="h-6 w-6 rounded-full" alt="Facebook" src={FacebookLogo}></img>
              </a>
             
              <a href="https://www.linkedin.com/company/mahonys-transport/posts/?feedView=all" className="hover:text-gray-300">
              <img className="h-6 w-6 rounded-full" alt="LinkedIn" src={LinkedInLogo}></img>
              </a>
            </div>
          </div>
          
        </div>
        <div className="border-t border-white/20 mt-8 pt-8 text-center">
          <p>&copy; 2024 Mahonys Transport Services. All rights reserved.</p>
        </div>
        
        <div className=" border-white/20 pt-8 text-right">
        <p>Website By</p>
          <a href="https://clutchit.com.au/" className="hover:underline">
             Clutch Australia
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
