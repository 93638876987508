import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import bulkStorageImage from "../assets/Mahonys-Kenworth-Shed.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import BreadCrumb from "../components/BreadCrumb";
import { Link, Navigate } from "react-router-dom";
import { ServiceCards } from "./Services";
import Hero from "../components/Hero";

function FumigationStorage() {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Services", "/services"],
    Fumigation: ["Bulk Import & Storage", "/services/fumo"],
  };
  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={bulkStorageImage}
        title={"BULK IMPORT & STORAGE"}
        subText={"Your Premier Bulk Storage Solution"}
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        <h2 className="text-4xl font-semibold mb-8 text-green-900 leading-tight">
          Comprehensive Bulk Import & Storage Solutions
        </h2>
        <p className="mb-6 text-lg leading-relaxed text-gray-700">
          At Mahonys Transport Services, we provide exceptional bulk storage
          solutions tailored to meet the diverse needs of businesses in the
          logistics and packing industry. We have the capacity to safely store
          large quantities of various commodities, making us a leading choice in
          the Melbourne area. Our modern facilities and comprehensive storage
          solutions ensure your bulk imports are handled with the utmost care.
        </p>
        <p className="mb-8 text-lg leading-relaxed text-gray-700">
          Our experienced team is equipped to manage all aspects of your storage
          needs, ensuring that your products are secure and easily accessible
          whenever required. We pride ourselves on our ability to adapt to our
          clients' evolving demands.
        </p>

        <Link to="/contactUs">
          <button className="px-4 py-2 bg-green-900 text-white hover:bg-black rounded-lg text-lg font-medium transition-all duration-300">
            Contact Us
          </button>
        </Link>

        <h2 className="text-3xl font-semibold mb-6 mt-10 text-green-800">
          Strategic Location
        </h2>
        <p className="mb-6 text-lg leading-relaxed text-gray-700">
          Conveniently situated from Melbourne Wharf, our facility is perfectly
          positioned for efficient bulk import and storage solutions. This prime
          location allows for seamless logistics operations and quick access to
          major transport routes.
        </p>

        <h2 className="text-3xl font-semibold mb-6 text-green-800">
          State-of-the-Art Facilities
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Modern Equipment: </strong>Our facilities are equipped with
            the latest technology designed to meet diverse storage needs,
            ensuring that your goods are stored safely and efficiently.
          </li>
          <li>
            <strong>Flexible Storage Solutions: </strong>We offer secure storage
            options for a wide range of bulk products, accommodating various
            commodities while maintaining their integrity.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-6 text-green-800">
          Unmatched Flexibility
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Flexible Operating Hours: </strong>We understand the
            importance of accommodating your business needs. Our flexible
            operating hours allow us to receive inturns and deliver outturns
            outside of normal business hours.
          </li>
          <li>
            <strong>Adaptable Services: </strong>Whether you require special
            handling or unique storage solutions, we can tailor our services to
            fit your specific requirements and schedule.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-6 text-green-800">
          Superior Quality Assurance
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>AQIS Accredited Facilities: </strong>Our bulk storage
            facilities fully comply with Australian Quarantine and Inspection
            Services (AQIS) standards, guaranteeing the highest safety and
            quality levels for your stored goods.
          </li>
          <li>
            <strong>Protection and Integrity: </strong>We strictly adhere to
            safety regulations to ensure the protection and integrity of your
            commodities, providing peace of mind for our clients.
          </li>
        </ul>

        <div className="max-w-[1300px] my-8 flex justify-center lg:justify-start">
          <a
            href="tel:0393718700"
            className="flex items-center px-4 py-2 bg-[#014432] text-white font-normal rounded-md shadow-md hover:bg-black transition-all duration-300"
          >
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            Call Us
          </a>
        </div>
      </div>

      <h2 className="text-3xl font-semibold text-center text-green-800 mb-8">
        OTHER SERVICES
      </h2>
      <ServiceCards hiddenTitle="BULK IMPORT & STORAGE" />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default FumigationStorage;
