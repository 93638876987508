import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import transportLogisticsImage from "../assets/Mahonys-Scania-Kenworth.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import BreadCrumb from "../components/BreadCrumb";
import { Link, Navigate } from "react-router-dom";
import { ServiceCards } from "./Services";
import Hero from "../components/Hero";

function Logistics() {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Services", "/services"],
    Logistics: ["Transport & Logistics", "/services/logistics"],
  };

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={transportLogisticsImage}
        title={"TRANSPORT & LOGISTICS"}
        subText={"Your Premier Partner in Transport and Logistics "}
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        <h1 className="text-4xl font-semibold mb-8 text-green-900 leading-tight">
          Logistics Services
        </h1>
        <p className="mb-6 text-lg leading-relaxed text-gray-700">
          At Mahonys Transport Services, we are dedicated to providing
          unparalleled transport and logistics solutions tailored to meet the
          diverse needs of businesses across various industries. Our commitment
          to compliance, safety, and efficiency makes us the ideal partner for
          all your transport and logistics needs.
        </p>
        <p className="mb-6 text-lg leading-relaxed text-gray-700">
          Our fleet includes modern Kenworth trucks with a variety of trailer
          combinations, such as B-Doubles, single tippers, drop-decks, and
          tankers. This versatility allows us to transport an assortment of
          goods, including hay, machinery, timber, and bulk products.
        </p>
        <p className="mb-6 text-lg leading-relaxed text-gray-700">
          We pride ourselves not only on the quality and efficiency of our
          services but also on the positive image we present, assuring that your
          business’s goodwill is enhanced, not impaired. Our fleet undergoes
          stringent maintenance to exceed safety and compliance regulations,
          ensuring timely deliveries.
        </p>
        <p className="mb-8 text-lg leading-relaxed text-gray-700">
          Our friendly and motivated logistics team is always eager to meet your
          business needs. With a wealth of industry knowledge, we are committed
          to providing reliable transport solutions that help you succeed.
        </p>

        <h2 className="text-3xl font-semibold mb-6 text-green-800 leading-tight">
          Unmatched Compliance and Safety
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>
              Full National Heavy Vehicle Regulator Accreditation:
            </strong>{" "}
            We comply with all regulations regarding Mass, Maintenance, Fatigue,
            and Chain of Responsibility to ensure safe operations.
          </li>
          <li>
            <strong>Comprehensive Risk Assessment:</strong> Our management
            policies exceed safety and road compliance regulations, ensuring the
            highest standards are maintained at all times.
          </li>
          <li>
            <strong>Safety First:</strong> We prioritize the safety of our team,
            clients, and the public, guaranteeing peace of mind in every
            transport operation.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-6 text-green-800 leading-tight">
          State-of-the-Art Fleet
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Modern Trucks:</strong> Our fleet consists of
            top-of-the-line Kenworth and Scania B-double and A-double trucks,
            regularly upgraded and meticulously maintained to ensure
            reliability.
          </li>
          <li>
            <strong>Versatile Trailer Combinations:</strong> We offer various
            trailer configurations to accommodate diverse cargo needs, ensuring
            efficient transport solutions for any product.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-6 text-green-800 leading-tight">
          Comprehensive Bulk Transport Solutions
        </h2>
        <p className="mb-6 text-lg leading-relaxed text-gray-700">
          Mahonys Transport Services provides efficient delivery solutions for a
          wide range of products, including:
        </p>
        <ul className="mb-8 ml-6 font-medium list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>Grain, Fertilizer, Sand & Rock</li>
          <li>Cottonseed, Legumes, Bi-Products</li>
          <li>Compost and Organics</li>
        </ul>
        <p className="mb-8 text-lg leading-relaxed text-gray-700">
          We guarantee on-time deliveries within budget, enhancing your
          business's goodwill through our professional image and dependable
          service.
        </p>

        <h2 className="text-3xl font-semibold mb-6 text-green-800 leading-tight">
          Integrated End-to-End Logistics
        </h2>
        <p className="mb-8 text-lg leading-relaxed text-gray-700">
          Our logistics solutions seamlessly combine freight and export packing
          capabilities, providing tailored solutions that adapt to your unique
          business needs. We leverage our industry connections to facilitate
          more profitable dealings and streamline your logistics processes.
        </p>

        <div className="max-w-[1300px] my-8 flex justify-center lg:justify-start">
          <a
            href="tel:0393718750"
            className="flex items-center px-4 py-2 bg-[#014432] text-white font-normal rounded-md shadow-md hover:bg-black transition-all duration-300"
          >
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            Call Us
          </a>
        </div>

        <h2 className="text-3xl font-semibold mb-6 text-green-800 leading-tight">
          The Mahonys Difference
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Highly Skilled Team:</strong> Our motivated logistics team
            and drivers possess extensive industry knowledge, ensuring top-notch
            service.
          </li>
          <li>
            <strong>Commitment to Quality:</strong> We focus on efficiency and
            cost-effectiveness, consistently delivering excellence in transport
            services.
          </li>
          <li>
            <strong>Building Relationships:</strong> Our experience in the
            agriculture industry fosters strong relationships, leading to more
            profitable opportunities for your business.
          </li>
        </ul>

        <Link
          to="/contactUs"
          className="text-green-800 font-semibold hover:underline transition-all duration-300"
        >
          Contact Us &gt;
        </Link>
      </div>

      <h2 className="text-3xl font-semibold text-center text-green-800 mb-8">
        OTHER SERVICES
      </h2>
      <ServiceCards hiddenTitle="TRANSPORT & LOGISTICS" />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Logistics;
