import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import ContactUsCard from "../components/ContactUs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const Contact_Us = () => {
  return (
    <div>
      <NavBar />

      <div className="max-w-[1300px] mx-auto my-5 flex justify-center lg:justify-end">
        <a
          href="tel:0393718700"
          className="flex items-center px-2 py-2 bg-[#014432] text-white font-normal rounded-md shadow-md hover:bg-black transition duration-200"
        >
          <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
          03 9371 8700
        </a>
      </div>
      <div className="max-w-[1300px] mx-auto my-10">
        <div className="flex flex-col lg:flex-row gap-8 mb-8">
          <div className="flex-grow">
            <ContactUsCard />
          </div>

          {/* Address Card */}
          <div className="w-full lg:w-1/3 bg-white rounded-lg shadow-lg p-6 ">
            <h3 className="text-lg lg:text-xl font-semibold text-green-800 mb-1 mt-4">
              Head Office
            </h3>
            <p className="mb-2 font-semibold text-sm">
              Transport & Logistics, Sales and Accounts
            </p>
            <address className="not-italic text-sm lg:text-base">
              <p>1-9</p>
              <p>Marriner Street</p>
              <p>Colac</p>
              <p>Victoria, 3250</p>
              <p className="mt-2">
                <a href="tel:0393718750">Phone: +61 3 9371 8750</a>
              </p>
              <p className="mt-2 mb-6">
                <a href="mailto:logistics@mahonystransport.com.au">
                  logistics@mahonystransport.com.au
                </a>
              </p>
            </address>
            <h3 className="text-lg lg:text-xl font-semibold text-green-800 mb-1 mt-4">
              Melbourne Office
            </h3>
            <p className="mb-2 font-semibold text-sm">
              Storage, Import & Export Containers
            </p>
            <address className="not-italic text-sm lg:text-base">
              <p>115-117</p>
              <p>William Angliss Drive</p>
              <p>Laverton North</p>
              <p>Victoria, 3026</p>
              <p className="mt-2">
                <a href="tel:0393718700">Phone: +61 3 9371 8700</a>
              </p>
              <p className="mt-2 mb-4">
                <a href="mailto:melbourne@mahonystransport.com.au">
                  melbourne@mahonystransport.com.au
                </a>
              </p>
            </address>
          </div>
        </div>

        {/* Business Contacts Card */}
        <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-6">
          <h3 className="text-lg lg:text-xl font-bold text-green-800 mb-4">
            Business Contacts
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="p-2">
              <h4 className="font-bold text-base lg:text-lg">
                Business Relations Manager
              </h4>
              <p className="font-semibold text-green-800 text-sm lg:text-base">
                Damian Farley
              </p>
              <p className="text-sm lg:text-base">Phone: +61 448 883 555</p>
              <p className="text-sm lg:text-base">
                <a href="mailto:damian@mahonystransport.com.au">
                  damian@mahonystransport.com.au
                </a>
              </p>
            </div>

            <div className="p-2">
              <h4 className="font-bold text-base lg:text-lg">
                Business Development Manager
              </h4>
              <p className="font-semibold text-green-800 text-sm lg:text-base">
                Phil Carse
              </p>
              <p className="text-sm lg:text-base">Phone: +61 422 120 197</p>
              <p className="text-sm lg:text-base">
                <a href="mailto:pcarse@mahonystransport.com.au">
                  pcarse@mahonystransport.com.au
                </a>
              </p>
            </div>
            <div className="p-2">
              <h4 className="font-bold text-base lg:text-lg">
                Chief Operating Officer
              </h4>
              <p className="font-semibold text-green-800 text-sm lg:text-base">
                Luke Mahony
              </p>
              <p className="text-sm lg:text-base">Phone: +61 448 080 022</p>
              <p className="text-sm lg:text-base">
                <a href="mailto:damian@mahonystransport.com.au">
                  luke@mahonystransport.com.au
                </a>
              </p>
            </div>
            <div className="p-2">
              <h4 className="font-bold text-base lg:text-lg">
                Chief Executive Officer
              </h4>
              <p className="font-semibold text-green-800 text-sm lg:text-base">
                Jacob Mahony
              </p>
              <p className="text-sm lg:text-base">Phone: +61 438 925 410</p>
              <p className="text-sm lg:text-base">
                <a href="mailto:damian@mahonystransport.com.au">
                  jmahony@mahonystransport.com.au
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact_Us;
