import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import importExportImage from "../assets/Mahonys-Dark-Kenworth.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import { Link, ScrollRestoration } from "react-router-dom";
import { ServiceCards } from "./Services";
import Hero from "../components/Hero";

function ImportExport() {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Services", "/services"],
    ImportExport: [
      "Import & Export Containers Packing",
      "/services/import_export",
    ],
  };

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={importExportImage}
        title={
          <div>
            IMPORT & EXPORT CONTAINERS<br></br>
            PACKING
          </div>
        }
        subText={"Your Gateway to Seamless Import-Export Solutions"}
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-8 w-[90%]">
        <h1 className="text-4xl font-semibold mb-8 text-green-900 leading-tight">
          Efficient Import & Export Solutions in Melbourne’s Logistics Hub
        </h1>
        <p className="mb-6 text-lg leading-relaxed text-gray-700">
          At Mahonys Transport Services, we specialize in seamless import and
          export container packing tailored to meet the needs of businesses
          across various industries. With over a decade of experience, we have
          established ourselves as a leader in providing efficient and reliable
          solutions for bulk and containerized goods, including bi-products,
          stock feeds, and fertilizers.
        </p>
        <p className="mb-8 text-lg leading-relaxed text-gray-700">
          Our strategic location in the heart of Melbourne’s container industry
          hub gives us direct access to all major export services and proximity
          to the Melbourne Wharf, ensuring fast and cost-effective logistics
          solutions.
        </p>

        <h2 className="text-3xl font-semibold mb-6 text-green-800">
          Comprehensive Import Services
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Quarantine Approval Premises (QAP Class 2.3):</strong> Fully
            licensed and accredited by the Department of Agriculture, Fisheries
            and Forestry (DAFF), we handle your bulk and containerized goods
            with the highest safety and compliance standards.
          </li>
          <li>
            <strong>Bulk Storage:</strong> With a 25,000m³ storage capacity, we
            offer secure long-term storage for your goods, ensuring seamless
            transition from import to delivery.
          </li>
          <li>
            <strong>Tipper Fleet:</strong> Our modern fleet provides quick and
            efficient transport services, moving your goods safely from dock to
            destination.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-6 text-green-800">
          Expert Export Container Packing
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Domestic & International Export Packing:</strong> We offer
            flexible, customized packing solutions to meet the specific
            requirements of your business, whether you’re shipping locally or
            internationally.
          </li>
          <li>
            <strong>State-of-the-Art Equipment:</strong> We use the latest
            packing technologies to ensure that your containers are packed
            efficiently and securely.
          </li>
          <li>
            <strong>High-Volume Capacity:</strong> Our export division can pack
            up to 2,400 metric tons per day, handling both 20ft and 40ft
            containers.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-6 text-green-800">
          Unmatched Quality Assurance
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>AQIS Accredited Facilities:</strong> Our facilities meet all
            Australian Quarantine and Inspection Services (AQIS) regulations,
            ensuring that your goods are handled with the highest level of care.
          </li>
          <li>
            <strong>Compliance with QAP Class 2.3:</strong> Strict adherence to
            quarantine regulations guarantees that your import and export
            processes run smoothly, with full preparation of inspection and
            export documentation.
          </li>
        </ul>

        <h2 className="text-3xl font-semibold mb-6 text-green-800">
          The Mahonys Advantage
        </h2>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Tailored Solutions:</strong> As a medium-sized company, we
            offer personalized attention and solutions customized to your
            business's unique needs.
          </li>
          <li>
            <strong>Industry Expertise:</strong> With over 10 years of
            experience in the import-export commodity markets, we ensure that
            your logistics are managed efficiently and cost-effectively.
          </li>
          <li>
            <strong>Flexible Operating Hours:</strong> We understand the
            fast-paced nature of logistics, which is why we offer flexible
            working hours to suit your schedule.
          </li>
        </ul>

        <p className="mb-8 text-lg leading-relaxed text-gray-700">
          At Mahonys Transport Services, we go beyond just container packing. We
          optimize your entire supply chain to ensure timely, cost-effective,
          and secure deliveries. Whether you're importing bi-products or
          exporting bulk commodities, our experienced team is here to streamline
          your logistics processes from start to finish.
        </p>

        <Link
          to="/contactUs"
          className="text-green-800 font-semibold hover:underline text-lg"
        >
          Contact Us &gt;
        </Link>

        <h2 className="text-3xl font-semibold mb-6 mt-10 text-green-800">
          Fumigation Services
        </h2>
        <p className="mb-8 text-lg leading-relaxed text-gray-700">
          In addition to our import and export packing services, Mahonys
          Transport Services offers professional fumigation services to protect
          your goods from pests and meet international shipping standards.
          Fumigation is a crucial step to ensure that products comply with
          quarantine regulations, especially for perishable goods, stock feeds,
          and agricultural products.
        </p>
        <ul className="mb-8 ml-6 list-disc list-outside text-lg text-gray-700 space-y-4">
          <li>
            <strong>Certified and Compliant:</strong> Our fumigation services
            comply with Australian and international quarantine requirements,
            ensuring your goods are pest-free before shipment.
          </li>
          <li>
            <strong>Advanced Techniques:</strong> We use the latest fumigation
            technologies and methodologies to ensure thorough treatment of
            containerized and bulk goods.
          </li>
          <li>
            <strong>Seamless Integration:</strong> Our fumigation services can
            be integrated seamlessly with our packing and storage solutions,
            providing you with an all-in-one service for your logistics needs.
          </li>
        </ul>

        <div className="max-w-[1300px] my-8 flex justify-center lg:justify-start">
          <a
            href="tel:0393718700"
            className="flex items-center px-4 py-2 bg-[#014432] text-white font-normal rounded-md shadow-md hover:bg-black transition-all duration-300"
          >
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            Call Us
          </a>
        </div>
      </div>

      <h2 className="text-3xl font-semibold text-center text-green-800 mb-8">
        OTHER SERVICES
      </h2>
      <ServiceCards hiddenTitle="IMPORT & EXPORT CONTAINERS PACKING" />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default ImportExport;
