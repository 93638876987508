import React from "react";

import Footer from "../components/footer";
import aboutUsBg from "../assets/Mahonys-Kenworth-Shed.jpg"; // Make sure you have this image in your assets folder

import Hero from "../components/Hero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

function About() {
  const crumb = {
    Home: ["Home", "/"],
    About: ["About Us", "/about"],
  };

  const employees = [
    // Founders
    {
      name: "Bill Mahony",
      position: "Founder",
      description: (
        <>
          <p>
            Bill started Mahonys Transport Services with Monica as an owner
            driver in 1997. He is a very passionate and motivated person with
            40+ years experience in the agricultural, bulk freight and import &
            export container and bulk storage industries.
          </p>
          <p>
            Bill actively seeks new and innovative business opportunities for
            both Mahonys Transport and its clients, often drawing on his
            industry knowledge to connect potential business partners.
          </p>
          <p>
            As a father of three, Bill is closely involved in his children’s
            lives, particularly following their sporting and educational
            achievements.
          </p>
        </>
      ),
    },
    {
      name: "Monica Mahony",
      position: "Founder / Company Secretary",
      description: (
        <>
          <p>
            Monica started Mahonys Transport Services with Bill in 1997. She
            managed to simultaneously combine managing the company's accounts
            and raising three children.
          </p>
          <p>
            Monica has more than 20 years experience in secretarial and
            administration roles. She also has extensive experience in the
            agricultural industry after being brought up on a Dairy farm in the
            Western District.
          </p>
          <p>
            As a loving mother of three children, Monica enjoys spending
            valuable time with her family and friends.
          </p>
        </>
      ),
    },

    // Executive Management
    {
      name: "Jacob Mahony",
      position: "CEO",
      description: (
        <>
          <p>
            Jacob has several years of experience in the agriculture industry,
            specialising in accounting and business processes.
          </p>
          <p>
            Although only joining Mahonys Transport Services in a full-time role
            in 2015, Jacob has been involved with Mahonys Transport over the
            past two decades in a variety of casual roles. This introduction has
            given Jacob a good insight into Import & Export containers & bulk
            flowpaths.
          </p>
          <p>
            Away from Mahonys Transport, Jacob enjoys socialising, sports and
            travel.
          </p>
        </>
      ),
    },
    {
      name: "Luke Mahony",
      position: "COO",
      description: (
        <>
          <p>
            Luke graduated from university in Melbourne with a degree in Science
            and has been involved in the family business from a young age.
            Starting as a teenager, he gained valuable hands-on experience in
            operational processes within the agricultural industry.
          </p>
          <p>
            Over the years, Luke has developed a deep understanding of the
            business and is committed to fostering strong client relationships.
            He takes pride in ensuring that every client of Mahonys receives
            exceptional service and support.
          </p>
          <p>
            Outside of work, Luke lives on the Surfcoast and is passionate about
            outdoor activities, including surfing, mountain biking, football,
            and traveling.
          </p>
        </>
      ),
    },

    // Business Development & Operations
    {
      name: "Damian Farley",
      position: "Business Relations Manager",
      description: (
        <>
          <p>
            As head of business development, Damian is constantly looking at
            processes to enhance valued clients and new client's requests. He is
            committed to the (KISS) principle: Keep It Simple Sunny.
          </p>
          <p>
            Damian has thorough knowledge and understanding of Import and Export
            Container and Bulk requirements, procedures, and costings. He
            prefers to operate very openly and honestly with our customers and
            stakeholders to ensure not only long-term business relationships but
            also friendships.
          </p>
          <p>Damians wit and personality will keep you on your toes.</p>
        </>
      ),
    },
    {
      name: "Phil Carse",
      position: "Business Development Manager",
      description: (
        <>
          <p>
            Phil has numerous years of management experience, with roles in the
            AFL system including Business Operations Manager in Queensland.
          </p>
          <p>
            He understands the importance of clear and accurate communication
            with all of our clients to make sure everyone along the supply chain
            is thrilled with the end result for their business.
          </p>
          <p>
            Outside of Mahonys, Phil is based in Brisbane with his wife and
            three children; he also balances the senior coaching role of the
            Redlands football club.
          </p>
        </>
      ),
    },
    {
      name: "Rose Messer",
      position: "General Manager Operations",
      description: (
        <>
          <p>
            Over her 15+ year career in agriculture, Rose has experienced many
            areas of the industry first-hand - from driving headers and marking
            lambs to milking cows and testing grain. Her extensive background in
            export packing, bulk transport, and logistics complimented with an
            MBA brings depth to Mahonys operations.
          </p>
          <p>
            Since joining Mahonys in 2016, Rose has excelled at building strong
            teams, streamlining processes, and managing complex projects. Her
            passion for operational excellence and seamless execution keeps her
            engaged in all aspects of the business.
          </p>
          <p>
            Rose’s weekends are spent watching an unhealthy amount of junior
            sport, or away in the caravan with her family.
          </p>
        </>
      ),
    },

    // Operations & Logistics
    {
      name: "Alec Stead",
      position: "Import Export Manager",
      description: (
        <>
          <p>
            Alec has 20+ years of management experience, with roles throughout
            supermarket, meat industries & the agricultural sector. Alec has a
            passion for achieving our Import & Export container packing &
            unloading in a timely & efficient manner; through building a great
            work culture with our Melbourne team, to ultimately achieve the
            greatest outcome and service possible for our customers.
          </p>
          <p>
            Outside of work, Alec is a proud and caring father who enjoys family
            time, barracking for Hawthorn, playing soccer, and traveling.
          </p>
        </>
      ),
    },

    {
      name: "Tina Tuominiemi",
      position: "Transport Manager",
      description: (
        <>
          <p>
            With three decades of experience in international trade, Tina brings
            extensive expertise to all facets of supply chain management and
            logistics.{" "}
          </p>
          <p>
            Her approach combines strategic innovation with genuine
            partnership-building, ensuring each client receives tailored
            solutions.{" "}
          </p>
          <p>
            Her leadership philosophy centres around creating a positive and
            collaborative work environment which has a flow on effect to
            customer experiences.
          </p>
          <p>
            Away from logistics, Tina explores and photographs the waterfalls
            and wildlife of the Otway Rangers she calls home.{" "}
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={aboutUsBg}
        title={
          <h2>
            ABOUT
            <br />
            MAHONYS
            <br />
            TRANSPORT
          </h2>
        }
        subText={
          <p className="text-white text-l mb-10">
            Learn more about our commitment
            <br />
            to excellence in logistics
          </p>
        }
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        <h2 className="text-3xl font-bold mb-4 text-green-800">Our Story</h2>
        <p className="mb-4">
          For nearly three decades, Mahonys Transport Services has been at the
          forefront of the transportation industry. Born in 1997 as a modest
          local operation, we've blossomed into a comprehensive logistics
          powerhouse, serving clients across Australia. Our journey is a
          testament to the power of family values in business, as we've grown
          from a single owner-driver to a dynamic team of logistics experts.
        </p>
        <p className="mb-6">
          Mahonys values an unwavering commitment to reliability, efficiency,
          and customer satisfaction. We've ridden the waves of industry changes,
          continually investing in cutting-edge technology and expanding our
          services to meet our clients' evolving needs.
        </p>

        <h2 className="text-3xl font-bold mb-4 text-green-800">
          Our Comprehensive Services
        </h2>
        <p className="mb-4">
          At Mahonys, we're strategically positioned to meet all your logistics
          needs. Our proximity to the Melbourne Wharf gives us a competitive
          edge, allowing us to offer a wide range of services with unparalleled
          efficiency:
        </p>
        <ul className="list-disc ml-10 mb-4">
          <li>
            <div className="inline font-semibold">
              Import and Export Container Packing:
            </div>{" "}
            Leveraging our modern equipment and strategic location, we ensure
            your goods are packed and shipped with utmost care and efficiency.
          </li>
          <li>
            <div className="inline font-semibold">
              Bulk Storage & Treatment:
            </div>{" "}
            Our state-of-the-art facilities provide secure and flexible storage
            solutions for various commodities.
          </li>
          <li>
            <div className="inline font-semibold">
              Bulk Commodity Transport:
            </div>{" "}
            We specialize in transporting grain, fertiliser, cotton seed,
            legumes, compost, and organics across Australia.
          </li>
          <li>
            <div className="inline font-semibold">
              Customized Logistics Solutions:
            </div>{" "}
            Whether you need container packing, bulk storage, or transport
            logistics, we tailor our services to suit your unique business
            requirements.
          </li>
        </ul>
        <p className="mb-4">
          Our fully accredited facilities comply with AQIS Standards,
          guaranteeing the highest level of quality and safety for your goods.
          With flexible operating hours and a team of seasoned professionals,
          we're always ready to adapt to your schedule and specific needs.
        </p>
        <p className="mb-4">
          With our transport division supporting the packing and export
          division, Mahonys Transport provide a total, end-to-end logistics
          solution, adapting to your unique needs with the flexibility and
          expertise that define the Mahonys difference.
        </p>
        <p className="mb-6">
          At Mahonys, we don't just offer services – we provide reliable,
          cost-effective solutions that drive your business forward. Our
          commitment to excellence, combined with our strategic advantages,
          makes us your ideal partner for all import, export, and storage needs.
        </p>
        <h2 className="text-3xl font-bold mb-6 text-green-800">Our Values</h2>
        <p className="mb-4">
          Our family roots have cultivated a strong set of values that guide
          everything we do:
        </p>
        <ul className="list-disc list-inside mb-6 font-medium">
          <li>Integrity in all our dealings</li>
          <li>Commitment to excellence</li>
          <li>Innovation in logistics solutions</li>
          <li>Environmental responsibility</li>
          <li>Customer-centric approach</li>
        </ul>

        <h2 className="text-3xl font-bold mb-6 text-green-800">
          Accreditations That Set Us Apart{" "}
        </h2>
        <p className="mb-4">We're proud to be accredited by:</p>
        <ul className="list-disc list-inside mb-6 font-medium">
          <li>National Heavy Vehicle Regulator</li>
          <li>AQIS (Australian Quarantine and Inspection Service) </li>
          <li>HACCP Food Safety Program</li>
          <li>Grain Trade Australia</li>
          <li>GACC Export Program</li>
          <li>NHVR - Chain of Responsibility</li>
        </ul>

        <h2 className="text-3xl font-bold mb-4 text-green-800">Our Vision</h2>
        <p className="mb-6">
          As we look to the future, we're committed to redefining excellence in
          the transport inustry. Our goal is to be the go-to provider for
          end-to-end logistics solutions, setting new benchmarks for service
          quality and client satisfaction.
        </p>

        <h2 className="text-3xl font-bold mb-4 text-green-800">
          The Mahonys Difference{" "}
        </h2>
        <p className="mb-4">
          With a shared passion for what we do, we're committed to forging
          lasting partnerships with our clients. We combine the warmth and
          personal touch of a family business with the efficiency and innovation
          of a modern logistics powerhouse.
        </p>
        <div className="max-w-[1300px]  my-5 flex justify-center lg:justify-start">
          <a
            href="tel:0393718700"
            className="flex items-center px-2 py-2 bg-[#014432] text-white font-normal rounded-md shadow-md hover:bg-black transition duration-200"
          >
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            Call Us
          </a>
        </div>
      </div>

      {/* Employee Section */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto w-[90%]">
          <h2 className="text-3xl font-bold mb-8 text-green-800 text-center">
            Our Team
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {employees.map((employee, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-xl font-bold mb-2">{employee.name}</h3>
                <p className="text-green-800 font-semibold mb-4">
                  {employee.position}
                </p>
                <div className="text-gray-600 space-y-4">
                  {employee.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default About;
